<template>
    <div style="display: flex">
        <v-data-table
            id="virtual-scroll-table-2"
            v-if="costs"
            :loading="loading"
            :headers="headers"
            disable-pagination
            hide-default-footer
            class="ma-0 pa-0"
            style="width: auto !important;"
            :items="costs"
        >
            <template v-slot:[`item.row`]="{ index }">
                {{ index + 1 }}
            </template>
            <template v-slot:[`item.margin`]="{ item }">
                {{ itemMargin(item) }}
            </template>
            <template v-slot:[`item.cost`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                        maximumFractionDigits: 2,
                    }).format(item.total2)
                }}
            </template>
            <template v-slot:[`item.grossMargin`]="{ item }">
                {{ itemGrossMargin(item) }}
            </template>
            <template v-slot:[`item.salePrice2`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                    }).format(item.salePrice2)
                }}
            </template>
            <template v-slot:[`item.pricePerQuantity2`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                    }).format(item.salePrice / quote.exchange / item.quantity)
                }}
                / {{ item.measure }}
            </template>

            <template v-slot:footer>
                <v-simple-table dense class="my-4">
                    <tfoot class="grey lighten-3">
                        <tr>
                            <td class="font-weight-bold">TOTAL</td>
                            <td class="font-weight-bold text-right">
                                {{
                                    new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: quote.currency,
                                    }).format(getTotalCosts())
                                }}
                            </td>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations, mapState } from 'vuex'

export default {
    name: 'Costs',
    props: {
        quoteId: {
            type: String,
            required: true,
        },
        quote: {
            type: Object,
            required: true,
        },
        fromProject: {
            type: Boolean,
            default: () => false,
        },
    },
    data: () => ({
        loading: false,
        errorMsg: '',
        costs: [],
        headers: [
            {
                text: 'Row',
                value: 'row',
                class: 'grey lighten-3',
                width: 50,
            },
            {
                text: 'COST',
                value: 'reference',
                class: 'grey lighten-3',
                width: 300,
            },
            {
                text: 'QTY',
                value: 'quantity',
                class: 'grey lighten-3',
            },
            {
                text: 'PRICE PER QUANTITY',
                value: 'pricePerQuantity2',
                class: 'grey lighten-3',
            },
            {
                text: 'SALE PRICE',
                value: 'salePrice2',
                class: 'grey lighten-3',
            },
        ],
    }),
    watch: {
        async quoteId() {
            await this.getCosts()
        },
    },
    async mounted() {
        try {
            this.setHeaders()
            await this.getCosts()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    computed: {
        ...mapState(['settings']),
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        setHeaders() {
            try {
                if (this.fromProject) {
                    this.headers.splice(3, 0, {
                        text: 'COST',
                        value: 'cost',
                        class: 'grey lighten-3',
                    })
                    this.headers.splice(4, 0, {
                        text: 'MARGIN',
                        value: 'margin',
                        class: 'grey lighten-3',
                    })
                    this.headers.splice(5, 0, {
                        text: 'GROSS MARGIN',
                        value: 'grossMargin',
                        class: 'grey lighten-3',
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        itemGrossMargin(item) {
            let totalCommission = 0
            if (this.quote.commissions) {
                this.quote.commissions.forEach(commission => {
                    totalCommission += parseFloat(commission.percentage) / 100
                })
            }

            const grossMargin =
                item.salePrice2 -
                item.total2 -
                item.salePrice2 * totalCommission

            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: this.quote.currency,
                maximumFractionDigits: 2,
            }).format(grossMargin)
        },
        itemMargin(item) {
            let totalCommission = 0
            if (this.quote.commissions) {
                this.quote.commissions.forEach(commission => {
                    totalCommission += parseFloat(commission.percentage) / 100
                })
            }

            const grossMargin =
                item.salePrice2 -
                item.total2 -
                item.salePrice2 * totalCommission

            const margin = (grossMargin * 100) / item.salePrice2

            return margin.toFixed(2) + '%'
        },
        getTotalCosts() {
            if (this.costs.length > 0) {
                return this.costs.reduce(
                    (acumulator, cost) => acumulator + cost.salePrice2,
                    0
                )
            }
            return 0
        },
        async getCosts() {
            try {
                this.loading = true
                this.errorMsg = null
                let costs = await API.getCosts(this.quoteId)
                costs = costs.filter(cost => cost.type)
                this.costs = costs.filter(cost => cost.type == 'cost')
            } catch (error) {
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
#virtual-scroll-table-2 >>> tbody {
    font-weight: normal;
}
</style>
